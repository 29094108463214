import React from "react";
// import YouTube from "react-youtube";

const ShopPageVideo = ({ title, videoCode }) => {
    return (
        <section className={`shop-page-video`}>
            <div className="container">
                <div className="shop-page-video-wrapper">
                    <h2
                        className="shop-page-video__title"
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    ></h2>

                    <div
                        className="shop-page-video-block"
                        dangerouslySetInnerHTML={{ __html: videoCode }}
                    >
                        {/* <YouTube videoId={videoId} /> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ShopPageVideo;
